import * as React from "react"
// import { Link } from "gatsby"

import { Container  } from 'react-bootstrap'

import Layout from "../components/layout"
import Seo from "../components/seo"

const SecondPage = () => (
  <Layout>
    <Container>
      <h1 className="text-center my-4 mt-5">Donate</h1>
      <p className="text-center">
        <a href="https://checkout.square.site/buy/V6MSHDMZAFZFWLDNL4SDC336" className="btn btn-primary">Donate to the Ralph Mark Gilbert Civil Rights Museum</a></p>
      </Container>
      
  </Layout>
)

export const Head = () => <Seo title="Donate" />

export default SecondPage
